import { StylesProvider } from '@material-ui/styles'
import { get } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { fetchAuth } from './container/auth/__redux__/actions'

import Loading from './component/loading'
import HeaderBox from './container/header'
import { setDataTcs } from './container/pdf/__redux__/action'
import { getRevalidateToken } from './domain/auth'
import { verifyUserTcs } from './domain/identity'
import { verifyAccess } from './domain/security'
import {
  Accounting,
  CustomerDashboard,
  CustomerSetup,
  CustomerSupport,
  Dashboard,
  DisputeInformation,
  DocumentWebSap,
  InterestFreeInvoices,
  Invoice,
  Quotations,
  Report
} from './pages'

import { Warnings } from './container/warnings'

import Menu from './component/menu'

const getShowMenuView = (state) => get(state, 'headerReducer.showMenuView')
const getAuth = (state) => get(state, 'auth.user')
const getPath = (user, path, roles) => (verifyAccess(user, roles) ? path : '/')

const rolesDashboard = [
  'OLP_ADMIN',
  'OLP_MANAGER',
  'OLP_BASIC_USER',
  'OLP_FINANCIER',
  'OLP_SUPPORT',
  'OLP_ADMIN_SJK'
]

export const rolesMananger = ['OLP_MANAGER']

export const rolesAdmin = ['OLP_CUSTOMER_SETUP', 'OLP_SUPPORT', 'OLP_FINANCIER']

export const rolesFullAccess = ['OLP_SUPPORT', 'OLP_FINANCIER']

const App = (props) => {
  const history = useHistory()
  const dispatchRedux = useDispatch()
  const user = useSelector(getAuth)
  const showMenuView = useSelector(getShowMenuView)
  const [tokenVerify, setTokenVerify] = useState(false)
  const [viewTcs, setViewTcs] = useState(false)
  const [redirectLogin, setRedirectLogin] = useState(false)

  const location = useLocation()

  const getComponent = useCallback(
    (user, component, roles) =>
      verifyAccess(user, roles) ? component : Invoice,
    []
  )

  const verifyValuesStorage = (storage) => (storage ? !!storage.value : false)

  const getInfosLocalStorage = () => {
    const inputSearchQuotation = localStorage.getItem('@inputSearchQuotation')
    const customerQuotation = JSON.parse(
      localStorage.getItem('@customerQuotation')
    )

    const inputSearch = localStorage.getItem('@inputSearch')
    const leader = JSON.parse(localStorage.getItem('@leader'))
    const camCsi = JSON.parse(localStorage.getItem('@camCsi'))
    const customerGroup = JSON.parse(localStorage.getItem('@customerGroup'))
    const customer = JSON.parse(localStorage.getItem('@customer'))

    return {
      invoices:
        (inputSearch && !!inputSearch) ||
        verifyValuesStorage(leader) ||
        verifyValuesStorage(camCsi) ||
        verifyValuesStorage(customerGroup) ||
        verifyValuesStorage(customer),
      quotations:
        (inputSearchQuotation && !!inputSearchQuotation) ||
        verifyValuesStorage(customerQuotation)
    }
  }
  const getReturnEpayments = useCallback((user, component, roles) => {
    const infoStorage = getInfosLocalStorage()
    if (infoStorage.invoices) return props.history.push('/app/invoices')
    if (infoStorage.quotations) {
      localStorage.clear()
      return props.history.push('/app/quotations')
    }
    return getComponent(user, component, roles)
  }, [])

  const getPageName = () => {
    const value = get(props.history, 'location.pathname', 'default')
    return value === '/' ? '/app/dashboard' : value
  }

  const verifyUserAssignTcs = async () => {
    try {
      await verifyUserTcs({
        system: 'OLP',
        userLogin: user.login,
        userEmail: user.email,
        customers: user.customers
      }).then((response) => {
        const assignTcs = response.data
        dispatchRedux(setDataTcs(assignTcs))
        if (assignTcs.accepted === false) setViewTcs(true)
        else setViewTcs(false)
      })
    } catch (err) {
      console.log('Error TCS - TCS Not Found!')
      setViewTcs(false)
    }
  }

  const checkJobsAcceptTcs = () => {
    const jobsTcs = process.env.REACT_APP_TCS_JOBS.split(',')
    const verifyJobs = jobsTcs.filter((job) => user.profile.includes(job))
    if (verifyJobs.length > 0) return true
    else return false
  }

  useEffect(() => {
    if (user) {
      setTokenVerify(true)
      setRedirectLogin(false)
      if (checkJobsAcceptTcs()) verifyUserAssignTcs()
    } else {
      if (sessionStorage.getItem('token')) {
        setRedirectLogin(false)
        getRevalidateToken()
          .then((response) => {
            fetchAuth(response.data.body)(dispatchRedux)
            setTokenVerify(true)
          })
          .catch((_err) => {
            window.location.href = process.env.REACT_APP_URL_PORTAL
          })
      } else {
        setTimeout(() => {
          if (!user && sessionStorage.getItem('token') === null) {
            setRedirectLogin(true)
          }
        }, 2000)
      }
    }
  }, [user])

  useEffect(() => {
    if (user) {
      const profile = user.profile.filter((profile) =>
        profile.startsWith('OLP')
      )
      ReactGA.event('fly_data', {
        login: user.login,
        company_code: user.accountCode,
        profile: profile
      })
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (redirectLogin && !user && !localStorage.getItem('token')) {
      const url = new URLSearchParams(location.search)
      
      const value = get(props.history, 'location.pathname', 'default')

      if(['/app/dispute-information'].includes(value)) {
        const documentNumber = url.get('documentNumber')
        const customerCode = url.get('customerCode')

        if (documentNumber && customerCode) {
          localStorage.setItem(
            '@dispute-filter',
            JSON.stringify({
              number: documentNumber,
              customerDispute: customerCode
            })
          )
        }

        window.location.href = process.env.REACT_APP_URL_PORTAL_CORP
      } else {
        window.location.href = process.env.REACT_APP_URL_PORTAL
      }
    }
  }, [redirectLogin])

  useEffect(() => {
    if (viewTcs) history.push('/tcs')
  }, [viewTcs])

  if (tokenVerify === false) return <Loading />
  else {
    return (
      <React.StrictMode>
        <StylesProvider injectFirst>
          <div className='App'>
            <div className='i-main'>
              <HeaderBox pageName={getPageName()} />
              <div className={showMenuView ? 'sep-menu-body' : 'sep-body'}>
                {showMenuView ? (
                  <Menu className='o-menu' versionProject='5.5.0' />
                ) : null}

                <div className='o-body'>
                  <Switch>
                    <Route
                      path={getPath(user, '/app', rolesDashboard)}
                      component={getReturnEpayments(
                        user,
                        Dashboard,
                        rolesDashboard
                      )}
                      exact
                    />
                    <Route
                      path={getPath(user, '/app/dashboard', rolesDashboard)}
                      component={getComponent(user, Dashboard, rolesDashboard)}
                      exact
                    />
                    <Route
                      path='/app/customer-dashboard'
                      component={CustomerDashboard}
                      exact
                    />
                    <Route
                      path={getPath(user, '/app/accounting', rolesAdmin)}
                      component={getComponent(user, Accounting, rolesAdmin)}
                      exact
                    />
                    <Route
                      path={getPath(user, '/app/customer-setup', [
                        ...rolesAdmin,
                        ...rolesMananger
                      ])}
                      component={getComponent(user, CustomerSetup, [
                        ...rolesAdmin,
                        ...rolesMananger
                      ])}
                      exact
                    />
                    <Route
                      path={getPath(user, '/app/document-sap-web', rolesAdmin)}
                      component={getComponent(user, DocumentWebSap, rolesAdmin)}
                      exact
                    />
                    <Route path='/app/invoices' component={Invoice} exact />
                    <Route
                      path='/app/quotations'
                      component={Quotations}
                      exact
                    />
                    <Route path='/app/report' component={Report} exact />
                    <Route
                      path={getPath(
                        user,
                        '/app/interest-free-invoices',
                        rolesFullAccess
                      )}
                      component={getComponent(
                        user,
                        InterestFreeInvoices,
                        rolesAdmin
                      )}
                      exact
                    />
                    <Route
                      path={'/app/customer-support'}
                      component={CustomerSupport}
                      exact
                    />
                    <Route
                      path={'/app/dispute-information'}
                      component={DisputeInformation}
                      exact
                    />
                  </Switch>

                  <Warnings />
                </div>
              </div>
            </div>
          </div>
        </StylesProvider>
      </React.StrictMode>
    )
  }
}

export default App
